var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.id],
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.allowSelect
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.disableOk,
                              size: "sm",
                              variant: "success",
                            },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$i18n.t(
                            "MANAGE" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _c("ListFilter", { on: { applyFilter: _vm.applyFilter } }),
          _vm.allowManage
            ? _c(
                "div",
                { staticClass: "grid-toolbar border" },
                [
                  _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_ADD_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.modalOpen(true)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  style: {
                                    color: "var(--grid-toolbar-button)",
                                  },
                                  attrs: { icon: ["far", "plus"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_ADD_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canView()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_EDIT_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableEdit },
                                on: {
                                  click: function ($event) {
                                    return _vm.modalOpen(false)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "pen-to-square"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_EDIT_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_DUPLICATE_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableDuplicate },
                                on: { click: _vm.showDuplicateDialog },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clone"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_DUPLICATE_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.duplicate")) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canDelete() && _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_MERGE_TAG_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              { on: { click: _vm.mergeTag } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "merge"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_MERGE_TAG_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("tag.button.merge")) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canDelete()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_DELETE_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableDelete },
                                on: { click: _vm.rowDelete },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "trash-can"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_DELETE_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_IMPORT_DOCUMENT_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              { on: { click: _vm.fileImport } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "inbox-in"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_IMPORT_DOCUMENT_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("tag.button.import_document")) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "span",
                    { attrs: { id: "BTN_EXPORT_DOCUMENT_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        { on: { click: _vm.fileExport } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "inbox-out"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_EXPORT_DOCUMENT_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("tag.button.export_document")) + " "
                      ),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-balham selector-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "tag-grid",
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: function (params) {
                return params.data.uuId
              },
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              pagination: "",
              paginationPageSize: 1000,
              cacheBlockSize: 10000,
              rowData: _vm.rowData,
              rowModelType: "serverSide",
              rowMultiSelectWithClick: "",
              rowSelection: _vm.multiple ? "multiple" : "single",
              serverSideInfiniteScroll: true,
              sideBar: false,
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: "",
              suppressContextMenu: "",
              suppressMultiSort: "",
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
      _vm.tagShow
        ? _c("TagModal", {
            attrs: { id: _vm.tagId, show: _vm.tagShow, title: _vm.tagTitle },
            on: {
              "update:show": function ($event) {
                _vm.tagShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.duplicateTitle,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.duplicateCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.showDuplicateNameError(),
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.duplicateOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.duplicate")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.duplicateShow,
            callback: function ($$v) {
              _vm.duplicateShow = $$v
            },
            expression: "duplicateShow",
          },
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("tag.field.name"), "label-for": "name" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": _vm.$t("tag.field.name"),
                      "data-vv-name": "duplicate.name",
                      "data-vv-delay": "500",
                      trim: "",
                    },
                    model: {
                      value: _vm.duplicateName,
                      callback: function ($$v) {
                        _vm.duplicateName = $$v
                      },
                      expression: "duplicateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showDuplicateNameError() },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.$t("error.not_unique_key")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("tag.merge_title"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.mergeCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              _vm.mergeFrom === null ||
                              _vm.mergeTo === null ||
                              _vm.mergeFrom === _vm.mergeTo,
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.mergeOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("tag.button.merge")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.mergeTagShow,
            callback: function ($$v) {
              _vm.mergeTagShow = $$v
            },
            expression: "mergeTagShow",
          },
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("tag.merge_from"), "label-for": "name" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.mergeOptions },
                    model: {
                      value: _vm.mergeFrom,
                      callback: function ($$v) {
                        _vm.mergeFrom = $$v
                      },
                      expression: "mergeFrom",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("tag.merge_to"), "label-for": "name" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.mergeOptions },
                    model: {
                      value: _vm.mergeTo,
                      callback: function ($$v) {
                        _vm.mergeTo = $$v
                      },
                      expression: "mergeTo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("tag.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.handleConfirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteModalShow,
            callback: function ($$v) {
              _vm.confirmDeleteModalShow = $$v
            },
            expression: "confirmDeleteModalShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.selected.length > 1
                      ? "tag.confirmation.delete_plural"
                      : "tag.confirmation.delete"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("GanttImportDialog", {
        attrs: {
          properties: [{ value: "name", text: _vm.$t("tag.field.name") }],
          mode: "TAG",
          show: _vm.docImportShow,
          title: _vm.$t("tag.button.import_document"),
        },
        on: {
          "modal-ok": _vm.docImportOk,
          "modal-cancel": _vm.docImportCancel,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }